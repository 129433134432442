import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Breadcrumbs from "@shared/ui/Breadcrumbs";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import getEnums from "$/settings/enums";
import PAID_FEATURES from "$/settings/paid-features.json";
import { TextAlignJustify } from "phosphor-react";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";
import BoxUIElement from "~/components/boxUIElement";
import EmptySpace from "~/components/_emptySpace";
import { NotFoundComponent } from "~/components/_notFoundMessage";
import MobileSearch from "~/components/header/MobileSearch";
import TextEditor from "~/components/general/editor";
import { serialize } from "~/components/general/editor/utils";
import { Accordion } from "~/components/accordion/_accordion";
import AppHeader from "~/components/header/AppHeader";
import Hamburger from "~/components/header/Hamburger";
import { trimText } from "~/utils/_initials";
import { getProductionLineById } from "../services/productionLine";
import MachineListItem from "../components/machine/MachineListItem";
import { useAuth } from "../components/general";
import { NoUserAssignedIcon } from "../icons";
import { getMachinesByParams } from "../services";
import { MachineLoaderComponent } from "../components/skeletonLoaders/_machineLoders";
import {
  ITEMS_BEFORE_PAGE,
  ITEMS_PER_PAGE,
  MAX_TEXT_DISPLAY_LENGTH,
} from "../constants/global";
import { Waypoint } from "react-waypoint";
import ContentLoading from "../components/ContentLoading";
import UnderlineTabsWrapper from "~/components/UnderlineTabsWrapper";

const paidFeaturesRef = getEnums(PAID_FEATURES, "reference");

const SingleProuctionLine = ({ intl, header }) => {
  const { id } = useParams();
  const { messages } = useIntl();
  const history = useHistory();
  const [machineSearchTerm, setMachineSearchTerm] = React.useState("");
  const { user } = useAuth();

  const isProductionLinePaid = user?.oem?.paidFeatures.includes(
    paidFeaturesRef.productionLines,
  );
  const isProductionLineDocumentationEnabled =
    user.facility.isProductionLineDocumentationEnabled;

  if (!isProductionLinePaid) history.replace(`/${intl?.locale}/app/`);

  const [productionLine] = getProductionLineById(id);

  const {
    machines = [],
    loading,
    totalCount,
    handleFetchMore,
  } = getMachinesByParams({
    where: {
      productionLine: id,
      searchQuery: machineSearchTerm,
    },
  });

  const productionLineDescription = useMemo(() => {
    let description = "";
    if (serialize(productionLine?.description)) {
      description = productionLine?.description;
    } else {
      description = "";
    }
    return description;
  }, [productionLine]);

  const handleSearch = (value) => {
    setMachineSearchTerm(value);
  };

  const breadCrumbs = React.useMemo(
    () => [
      {
        label: intl?.messages?.assets.breadCrumbs.assets,
        link: `/${intl?.locale}/app/assets`,
      },
      {
        label: intl?.messages?.assets.breadCrumbs.productionLines,
        link: `/${intl?.locale}/app/assets/production-lines`,
      },
      { label: trimText(productionLine?.name, MAX_TEXT_DISPLAY_LENGTH) || "" },
    ],
    [productionLine?.name],
  );

  const installationDate = useMemo(() => {
    return productionLine?.customFields?.find(
      (field) =>
        field?.fieldId?.slug === "installation-date" &&
        field?.fieldId?.isAdditionalField,
    );
  }, [productionLine]);

  const handleScrollToBottom = (event) => {
    if (
      machines.length >= ITEMS_PER_PAGE &&
      !loading &&
      event?.previousPosition !== "above" &&
      machines.length < totalCount
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: machines.length,
      });
    }
  };

  return (
    <>
      <div className="single-machine-container">
        <div className="fw-page-header u-flex u-items-center u-width-100">
          <AppHeader className="bordered u-width-100">
            <Breadcrumbs options={breadCrumbs} />
            {/* Hamburger component is only for mobile view */}
            <Hamburger />
          </AppHeader>
        </div>
        <div className="fw-page-content-wrapper u-flex u-flex-wrap u-width-100 u-padding-r-0">
          <div className="fw-page-main-content u-width-100">
            <div className="fw-machine-title">
              <Headline
                size={HEADLINE_SIZES.SMALL}
                className="max-md:line-clamp-2 max-md:text-ellipsis max-md:whitespace-normal"
              >
                {trimText(productionLine?.name, MAX_TEXT_DISPLAY_LENGTH)}
              </Headline>
            </div>
            <div className="page-details-tab">
              <UnderlineTabsWrapper
                tabs={[
                  {
                    id: "general-information",
                    label:
                      intl?.messages?.machines?.machineDetails.titleTabs
                        .details,
                    content: (
                      <div className="machine-details-container u-width-100">
                        <div className="machine-details-content u-width-100">
                          <div className="machine-meta-container u-items-center">
                            <div className="machine-meta">
                              <Label size={LABEL_SIZES.SMALL}>
                                {messages?.machines?.details}
                              </Label>
                              <EmptySpace height="14px"></EmptySpace>
                              <div className="u-flex u-width-100">
                                <div className="machine-meta-info u-margin-r-5">
                                  <BodyText
                                    size={BODY_TEXT_SIZES.X_SMALL}
                                    color="text-secondary"
                                    className="max-md:mr-2xs no-styles"
                                  >
                                    {intl?.messages?.productionLines?.labels
                                      ?.id + ":"}
                                  </BodyText>
                                  <EmptySpace height="4px"></EmptySpace>
                                  <BodyText
                                    size={BODY_TEXT_SIZES.X_SMALL}
                                    className="no-styles"
                                  >
                                    {trimText(
                                      productionLine?.lineId,
                                      MAX_TEXT_DISPLAY_LENGTH,
                                    )}
                                  </BodyText>
                                </div>
                                {installationDate?.fieldId?.enabled && (
                                  <div className="machine-meta-info">
                                    <BodyText
                                      size={BODY_TEXT_SIZES.X_SMALL}
                                      color="text-secondary"
                                      className="max-md:mr-2xs no-styles"
                                    >
                                      {messages?.machines?.installationDate +
                                        ":"}
                                    </BodyText>
                                    <EmptySpace height="4px"></EmptySpace>
                                    <BodyText
                                      size={BODY_TEXT_SIZES.X_SMALL}
                                      className="no-styles"
                                    >
                                      {installationDate?.value}
                                    </BodyText>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <Accordion
                            icon={<TextAlignJustify size={12} weight="bold" />}
                            title={messages?.machines?.description}
                          >
                            <TextEditor
                              content={productionLineDescription}
                              placeholder={" "}
                              readOnly={true}
                            />
                          </Accordion>
                        </div>
                      </div>
                    ),
                  },
                  {
                    id: "machines",
                    label: `${
                      intl.messages?.productionLines.labels.machines
                    } (${totalCount ?? 0})`,
                    content: (
                      <>
                        <MobileSearch
                          onChange={handleSearch}
                          value={machineSearchTerm}
                          className="production-line-machines-search"
                          isMobileElement={false}
                          placeholder={
                            intl?.messages?.machines?.searchPlaceholder
                          }
                        />
                        {!machines.length && !loading && (
                          <NotFoundComponent
                            icon={<NoUserAssignedIcon />}
                            title={
                              machineSearchTerm
                                ? intl.messages?.common?.noResultFound
                                : intl.messages?.productionLines.noMachinesFound
                            }
                          />
                        )}
                        {machines?.map((machine, index) => (
                          <React.Fragment key={machine?._id}>
                            <MachineListItem
                              intl={intl}
                              machine={machine}
                              inline
                            />
                            {index === machines.length - ITEMS_BEFORE_PAGE && (
                              <Waypoint onEnter={handleScrollToBottom} />
                            )}
                          </React.Fragment>
                        ))}
                        {loading && <MachineLoaderComponent total={1} />}
                      </>
                    ),
                  },
                  isProductionLineDocumentationEnabled && {
                    id: "documentation",
                    label:
                      intl?.messages?.machines?.machineDetails.titleTabs
                        .documentation,
                    content: (
                      <>
                        {productionLine &&
                          (!productionLine.documentFolders?.externalId ? (
                            <ContentLoading />
                          ) : (
                            <BoxUIElement
                              key={productionLine.documentFolders?.externalId}
                              token={user?.foldersAccessToken}
                              entityId={
                                productionLine.documentFolders?.externalId
                              }
                              rootFolderId={
                                productionLine.documentFolders?.externalId
                              }
                              entityName={productionLine?.name}
                              canUpload={false}
                              canRename={false}
                              canDelete={false}
                              canCreateNewFolder={false}
                            />
                          ))}
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProuctionLine;
