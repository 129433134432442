import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

import usePubnubInstance from "./_pubnub-unread-messages";

import FILE_SIZE from "$/settings/enums/files/_size.json";
import { chatAtom } from "~/atoms/_chat";
import Toast from "~/components/_toast";

const isDevelopment = process.env.NODE_ENV === "development";
let { maxFileSizeInMegabytes } = FILE_SIZE;

if (isDevelopment) maxFileSizeInMegabytes = 5;

/**
 * Pub nub hook.
 *
 * @param {Object} instance Chat instance from pub nub.
 * @param {Array} channels Channels for chat.
 * @param {Object} userInfo Info about user, injected from pubnub.
 */
export default (instance, channels, user, ticketId = "") => {
  if (!instance || !channels) {
    return null;
  }
  const pubnubInstance = usePubnubInstance(instance)();

  const [chatOptions, setChatOptions] = useRecoilState(chatAtom);

  const subscribeRef = useRef(false);
  const isFetchingRef = useRef(false);
  const isLoadingRef = useRef(true);
  const storedDownlaodsRef = useRef({});

  const [messages, setMessages] = useState([]);
  const [count, setCount] = useState();
  const [loadMore, setLoadMore] = useState(0);
  const [newMessage, setNewMessage] = useState({});

  /**
   * Get user info by publisher id
   *
   * @param {String} id Publisher id.
   * @return {Object} User info.
   */

  const getMessagesCount = () => {
    let messagesCount;
    isLoadingRef.current = true;

    instance.messageCounts(
      {
        channels,
        channelTimetokens: [Date.now()],
      },
      function (s, r) {
        if (r) {
          messagesCount = r.channels[channels[0]];
          setCount(messagesCount);
          isLoadingRef.current = false;
        }
      },
    );
  };

  /**
   * Send message to the channel.
   *
   * @param {String} messageText Message text.
   * @param {Function} callback Function after message is send.
   * @return {void}
   */
  var sendChatMessage = (messageText, callback, isFileMessage = false) => {
    if (messageText) {
      instance.publish(
        {
          channel: channels[0],
          message: {
            messageType: "text",
            text: messageText,
          },
          ...(isFileMessage && { meta: { isFileMessage } }),
        },
        function (status, response) {
          callback();
          if (status?.error) {
            toast.error(status?.error?.message);
          }
        },
      );
    }
  };

  /**
   * Get file by id.
   * @param {String} fileId File id.
   * @param {String} fileName Name
   * @return {Object}
   */
  var getFileById = (fileId, fileName) => {
    const result = instance.getFileUrl({
      channel: channels[0],
      id: fileId,
      name: fileName,
    });

    return result;
  };

  /**
   * Start download on click.
   *
   * @param {Object} file File.
   * @return {void}
   */
  var downloadFileLocaly = (file) => {
    return new Promise((resolve, reject) => {
      instance
        .downloadFile({
          channel: channels[0],
          id: file.id,
          name: file.name,
        })
        .then((response) => {
          let link = document.createElement("a");

          document.body.appendChild(link);

          link.href = URL.createObjectURL(response.data);
          link.target = "_blank";
          link.click();
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  /**
   * On download hook.
   *
   * @param {Object} file File.
   * @return {Promise}
   */
  var onDownload = (file) => {
    if (storedDownlaodsRef.current[file.id]) {
      return new Promise((resolve) =>
        resolve(storedDownlaodsRef.current[file.id]),
      );
    }
    return new Promise((resolve, reject) => {
      instance
        .downloadFile({
          channel: channels[0],
          id: file.id,
          name: file.name,
        })
        .then((response) => {
          const obj = {
            type: response.data.type,
            url: URL.createObjectURL(response.data),
          };
          storedDownlaodsRef.current[file.id] = obj;
          resolve(obj);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  /**
   * Send file and in message send file type.
   *
   * @param {Object} file File
   * @return {void}
   */
  var sendFile = (file, overflowErrorMessage) => {
    const bytes = file.size;
    const bytesToMegaBytes = bytes / (1024 * 1024);
    const rounded = Math.round((bytesToMegaBytes + Number.EPSILON) * 100) / 100;

    if (rounded > maxFileSizeInMegabytes) {
      toast(
        <Toast
          message={overflowErrorMessage.replace(
            "{size}",
            maxFileSizeInMegabytes,
          )}
          type="error"
        />,
        { closeButton: false },
      );
      toast.error();
      return;
    }

    instance.objects
      .getUUIDMetadata({
        uuid: user?.chatUUUID,
      })
      .then((response) => {
        const { data = {} } = response || {};
        if (data.externalId === user?._id) {
          let messageTime = (new Date().getTime() + 1 * 60 * 1000) * 10000;
          setMessages([
            ...messages,
            {
              timetoken: messageTime.toString(),
              message: {
                sending: true,
                file: {},
                sender: { ...data, ...data.custom },
              },
            },
          ]);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });

    instance.sendFile({
      channel: channels[0],
      message: {
        text: file?.name,
        type: file.type,
        size: bytes,
      },
      file,
    });
  };

  /**
   * Grouped messages by date.
   *
   * @param {Object} data Ticket.
   * @return Object
   */
  var groupedByDate = function (data) {
    const grouped = data.reduce((obj, message) => {
      const date = new Date(parseInt(message.timetoken) / 10000);
      const key =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      if (!obj[key]) obj[key] = [];
      obj[key].push(message);
      return obj;
    }, {});

    if (Object.keys(grouped).length === 0) return data;

    const transformed = {
      grouped: Object.entries(grouped)
        .map(([key, messages]) => {
          return {
            date: new Date(key),
            messages: messages.sort((a, b) => a.timetoken - b.timetoken),
          };
        })
        ?.sort((a, b) => a.date.getTime() - b.date.getTime()),
    };

    return transformed;
  };
  /**
   * Get messages for channel
   *
   * @param {Object} dateRange Start and end date.
   */
  const getMessages = (dateRange = {}) => {
    if (!isFetchingRef.current) {
      isFetchingRef.current = true;
      instance.fetchMessages(
        {
          channels: channels,
          ...dateRange,
          count: 25,
          includeMeta: true,
        },
        async function (status, response) {
          if (response) {
            let messagesData = response.channels[channels[0]];
            const allPublishers = [
              ...new Set([...(messagesData?.map?.((m) => m.uuid) ?? [])]),
            ];
            const publishersMetadata =
              await instance.objects.getAllUUIDMetadata({
                customFields: true,
                include: {
                  customFields: true,
                },
                filter: allPublishers
                  .map((p) => `custom.uuid == "${p}"`)
                  .join(" || "),
              });
            const publishersMap = allPublishers.reduce((obj, publisher) => {
              return {
                ...obj,
                [publisher]: publishersMetadata.data.find(
                  (p) => p.id === publisher,
                ),
              };
            }, {});

            if (messagesData?.length) {
              messagesData?.map((item) => {
                if (item.message.file) {
                  item.message = {
                    file: item.message.file,
                    text: item.message?.message?.text,
                    type: item.message?.message?.type,
                    size: item.message?.message?.size,
                  };
                  item.message.file = {
                    ...item?.message?.file,
                    url: getFileById(
                      item?.message?.file?.id,
                      item?.message?.file?.name,
                    ),
                  };
                }
                item.message.sender = {
                  ...(publishersMap?.[item.uuid] ?? {}),
                  ...(publishersMap?.[item.uuid]?.custom ?? {}),
                };
              });
              setMessages([...messagesData, ...messages]);
            }
          }
          isFetchingRef.current = false;
        },
      );
    }
  };

  /**
   * Attach pub nub listeneres.
   * @return {Object} Listeners
   */
  let attachListeners = {
    message: (m) => {
      if (m) {
        if (!m.message?.messageType) return;
        instance.objects
          .getUUIDMetadata({
            uuid: m.publisher,
          })
          .then((response) => {
            const { data = {} } = response || {};
            const isExternalSender = data.externalId !== user?._id;
            setNewMessage({
              externalSender: isExternalSender,
              time: m.timetoken,
            });

            if (ticketId && m.channel.includes(ticketId))
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  ...m,
                  message: {
                    ...m.message,
                    sender: { ...data, ...data.custom },
                  },
                },
              ]);

            if (!isExternalSender) {
              pubnubInstance.updateSingleMembership(
                {
                  id: m.channel,
                  custom: {
                    lastReadTimetoken: parseInt(
                      m?.timetoken ?? new Date().getTime() * 10000,
                    ),
                  },
                },
                true,
              );
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    },
    file: (m) => {
      if (m) {
        instance.objects
          .getUUIDMetadata({
            uuid: m.publisher,
          })
          .then((response) => {
            const { data = {} } = response || {};

            setNewMessage({
              externalSender: data.externalId !== user?._id,
              time: m.timetoken,
            });

            let obj = {
              ...m,
              message: {
                ...m.message,
                sending: false,
                sender: { ...data, ...data.custom },
                file: m.file,
              },
            };

            if (ticketId && m.channel.includes(ticketId)) {
              const newMessages = messages.map((mes, index) => {
                if (mes.message.sending) {
                  return obj;
                }

                return mes;
              });

              setMessages([
                ...newMessages,
                ...(data.externalId !== user?._id ? [obj] : []),
              ]);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    },
    // signal: function(s) {
    //   const channelName = s.channel; // Channel to which the signal belongs
    //   const channelGroup = s.subscription; // Channel group or wildcard subscription match, if any
    //   const pubTT = s.timetoken; // Publish timetoken
    //   const msg = s.message; // Payload
    //   const publisher = s.publisher; // Message publisher
    // },
  };

  /**
   * After new message is set,
   * update recoil.
   */
  useEffect(() => {
    setChatOptions({
      ...chatOptions,
      pubnub: {
        ...chatOptions?.pubnub,
        newMessage,
      },
    });
  }, [newMessage]);

  /**
   * On channel effect, subscribe or unsubsribe
   * get messages number in channel
   * and get messages for that period.
   *
   * @return {Function} Unsubscribe on component unmount.
   */
  useEffect(() => {
    if (!subscribeRef.current && channels?.length) {
      instance.subscribe({ channels });
      subscribeRef.current = true;

      getMessagesCount();
      getMessages();

      /**
       * Use recoil becuase is easier to call
       * function in components then pass as props.
       */
      setChatOptions({
        ...chatOptions,
        downloadedImages: [],
        pubnub: {
          newMessage: false,
          groupedByDate,
          messages,
          setMessages,
          messagesCount: count,
          loadMore,
          onLoadMore: setLoadMore,
          sendMessage: sendChatMessage,
          sendFile,
          onDownload,
          downloadFileLocaly,
        },
      });
    }

    return () => {
      instance.unsubscribe({ channels });
      setChatOptions({
        ...chatOptions,
        downloadedImages: [],
        pubnub: {},
      });
      subscribeRef.current = false;
    };
  }, [channels]);

  /**
   * Add listener and remove listener
   * on first load.
   *
   * @return {Function} Remove listener.
   */
  useEffect(() => {
    instance.addListener(attachListeners);

    return () => {
      instance.removeListener(attachListeners);
    };
  }, [messages]);

  /**
   * Get messages from time
   * when first message from prev render was sent.
   */
  useEffect(() => {
    if (!isFetchingRef.current) {
      getMessages({
        start: messages?.[0]?.timetoken,
      });
    }
  }, [loadMore]);

  return {
    groupedByDate,
    messages,
    setMessages,
    messagesCount: count,
    loadMore,
    onLoadMore: setLoadMore,
    sendMessage: sendChatMessage,
    sendFile,
    isFetching: isFetchingRef.current,
    isLoading: isLoadingRef.current,
  };
};
